@import "constants.scss";

body {
  background: $background;
  font-family: $default-font;
  color: $font-color;
}


header {
  padding: 37px 0;

  nav {
    a {
      padding: 7px 26px;
      border-radius: 30px;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      color: $surface-variant;
      background: $button-background-color;
      box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.1);
      text-decoration: none;
      display: inline-block;
    }
  }
}

.banner-wrapper {
  max-width: 100vw;
  overflow: hidden;


  .banner {
    background-image: url("/assets/frontend/banner-bg.png"),
    linear-gradient(to right, #FFF584, #DCF970);
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;

    height: 571px;
    border-radius: 20px;
    margin-bottom: 220px;

    .banner-images {
      position: relative;
      height: 100%;
      width: 50%;

      div {
        position: absolute;
        right: -35px;
        bottom: 0;
      }
    }
  }
}

footer {
  padding: 100px 15px;
  background-image: url("/assets/frontend/footer-bg.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  .container {


    .footer-left {
      p {
        font-size: 20px;

        a {
          color: $font-color;
        }
      }
    }

    nav {
      a {
        color: $font-color;
        display: block;
        font-size: 20px;

      }
    }
  }
}


#content {
  p.lead {
    margin-bottom: 300px;
    color: $surface-outline;

    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.67px;
    text-align: center;

  }
}


.highlight {
  background: $primary-color;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 4px;
}

strong {
  font-weight: 400;
}

#features {
  .card-img-custom {
    border-radius: 15px;

    &.e-1 {
      background: #FFDAD6;
    }

    &.e-2 {
      background: #E4E3D2;
    }

    &.e-3 {
      background: #CEEDA2;
    }

    &.e-4 {
      background: #FAFFCA;
    }
  }


  .card {
    border-radius: 15px;

    .card-title {
      font-weight: 500;
      font-size: 34px;
      line-height: 51px;
      letter-spacing: 0.67px;
    }

    .card-text.text-muted {
      font-weight: 400;
      font-size: 18.63px;
      line-height: 26.62px;
      letter-spacing: 0.33px;
      color: $surface-variant !important;
    }

    .card-text {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.67px;
      color: #112000;
    }
  }
}


#coming-soon {
  margin-top: 137px;

  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 65px;
    letter-spacing: -0.25px;
  }

  p.lead {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.67px;
    color: $surface-outline;
    margin-bottom: 100px;

    .highlight {
      color: #4D662B;
      background: transparent;
      font-weight: 600;
    }
  }

  .card {
    border-radius: 15px;

    .card-title {
      text-align: center;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0;
    }

    .card-text {
      font-weight: 300;
      font-size: 15px;
      line-height: 25.5px;
      letter-spacing: 0.25px;
      text-align: center;
    }
  }

  .lead-bottom {
    font-weight: 300;
    font-size: 17px;
    line-height: 25.5px;
    letter-spacing: 0.25px;
    text-align: center;

  }
}


#latest-update {
  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 65px;
    letter-spacing: -0.25px;
    text-align: center;

    small {
      font-weight: 400;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: 0;
      text-align: center;
      color: $surface-outline;
    }
  }

  p.lead {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.67px;
    text-align: center;
    color: $surface-outline
  }

  ul {
    li {
      background: #fff;
      border-radius: 60px;
      box-shadow: 0 1px 20px rgba(0, 0, 0, 0.07);
      margin: 8px;
      padding: 22px;
      font-weight: 400;
      font-size: 20px;
      line-height: 31.94px;
      letter-spacing: 0.67px;

      .check {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 100px;
        background: #DCF970;
      }

    }
  }
}


//facebobok boox
.card.facebook {
  background: #4267B2;
  padding: 15px;
  display: block;

  .logo {
    width: 50px;
    height: auto;
    object-fit: contain;
    top: 12px;
    right: 15px;
    position: absolute;
  }

  .header {
    color: #7387C2;
  }

  .brand {
    color: white;
    font-size: 22px;
    margin-bottom: 1rem;
  }

  .text {
    color: white;
    margin-bottom: 1rem;
    font-size: 85%;
    line-height: 1.3;
    text-align: justify;
  }

  .btn {
    color: white;
    background: #7387C2;
    display: block;
    font-weight: bold;
  }
}